import React from 'react';
import '../index.css';
// import WorkWithUs from '../components/WorkWıthUs';
import CalendarSlider from '../components/CalendarSlider';

export default function Anasayfa() {
  return (
    <>
      {/* Hero Alanı */}
      <div className="relative pt-12 w-full h-auto flex flex-col lg:flex-row bg-white">
        {/* Görsel Alanı */}
        <div className="w-full lg:w-3/4  flex items-center justify-center">
          <img
            src="/images/AnaSayfa.jpg"
            alt="Health Education"
            className="w-full h-3/4 lg:h-full object-cover shadow-md"
          />
        </div>

        {/* Bilgi Alanı */}
        <div className="w-full lg:w-1/2 flex items-center justify-center bg-[#111827] p-6 lg:p-12 text-left lg:text-left">
          <div className="text-white max-w-lg">
            <h1 className="text-md font-extrabold mb-4">
              Mezuniyet Öncesi Tıp Eğitiminde <br />"Olağan Dışı Durumlarda Temel Sağlık Hizmetleri Eğitimi" Programı Geliştirme
            </h1>
            <hr className="w-32 lg:w-56 border-white mb-4 mx-auto lg:mx-0" />
            <p className="text-sm mb-8">
              Deprem, pandemi, yangın, sel, savaş/çatışma ve benzeri olağan dışı durumların (ODD) sıklıkla yaşanıyor olması, hekimlerin bu durumlarda ne tip yetkinliklere/yeterliklere sahip olmaları gerektiği konusunu gündeme getirmektedir. “Çoklu Krizler Çağı” olarak adlandırılan içinden geçtiğimiz dönemde sıklıkla karşılaşılan ODD’ler, hekimlerin olağan çalışma koşullarında ihtiyaç duyduklarından farklı bilgi, beceri ve tutumlara sahip olmalarını zorunlu kılmaktadır. Buna rağmen, ODD’lerde temel sağlık hizmetlerinin yürütülebilmesi için gereksinim duyulan yetkinlikler hâlihazırda tıp fakültelerinin zorunlu müfredatları arasında yer almamakta, bu ihtiyaç ağırlıkla mezuniyet sonrasında gönüllülüğe dayanan kurslarda giderilmeye çalışılmaktadır. Varolan eğitim uygulamaları da bilimsel analizlerle ortaya çıkarılmış olmaktan çok sezgisel görünmektedir
            </p>
            <a href='/Hakkimizda/ProjeOzeti'>
              <button className="px-4 py-2 lg:px-6 lg:py-3 bg-[#FFFFFF] text-gray-900 font-bold rounded-full">
                Devamı için tıklayınız
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* Proje Hakkında ve CalendarSlider Bölümleri */}
      <div className="bg-gray-100 py-12 lg:py-12 px-4 ">
        <div className="max-w-8xl mx-auto flex flex-col lg:flex-row justify-between items-center md:max-w-7xl">




          {/* Proje Hakkında Bölümü */}
          <div className="w-full lg:w-1/2 text-center lg:text-left">
            <h2 className="text-3xl font-bold text-blue-800 ">PERSPEKTİF: ÇOKLU KRİZLER ÇAĞI, HEKİMLİK VE TIP EĞİTİMİ</h2>
            <div className='flex flex-col items-end pb-8 pr-8'>
              {/* <p >
                Ş. Erhan Rağcı<sup>1</sup>
              </p> */}
              {/* <p >
                Ekim 2024, Adana
              </p> */}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
              <p className="text-lg text-gray-800 leading-relaxed">
                Krizleri anlamak bugünün dünyasını anlamak ve değiştirebilmek için şart. Bu metin, “çoklu krizler çağı” olarak adlandırılan günümüz dünyasındaki kriz dinamiklerini anlamak üzere yapılacak tartışmalara ve çalışmalara genel bir kolaylaştırıcı düşünsel çerçeve çizmek üzere hazırlanmıştır. Söz konusu tartışmalar entelektüel bir fikir alışverişi olmanın yanı sıra en temelde pratik bir soruna çözüm üretmeyi hedeflemektedir: Çoklu krizler çağında hekimliğin ve tıp eğitiminin rolü ne olmalıdır?
              </p>
              <a href='/Web_Coklu_Krizler_Cagi.pdf' target='_blank'>
  <button className="px-4 py-2 lg:px-6 lg:py-3 bg-[#FFFFFF] text-gray-900 font-bold rounded-full">
   Devamı için tıklayınız
  </button>
</a>

            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <CalendarSlider />
          </div>
        </div>
      </div>

      {/* WorkWithUs Component */}
      {/* <div className="relative z-10">
        <WorkWithUs />
      </div> */}
    </>
  );
}
